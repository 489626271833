<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content 店铺
-->
<template>
  <comprehensive>
    <div class="shop-List">
      <section class="shop-context">
        <!--优质店铺banner-->
        <div class="shop-banner">
          <img src="../../assets/img/stockbanner.png" alt="大大买钢"/>
        </div>
        <!--地区导航-->
        <div
          class="shop-address"
          v-for="(items, name, indexs) in regionProductList"
          :key="indexs"
        >
          <div class="address-title">
            <p>{{ seachListName[name] }}</p>
          </div>
          <div
            class="address-list"
            :style="{
              height: (shopHeight[indexs].clickHeight + 1) * 46 + 'px',
            }"
          >
            <ul :ref="name">
              <li
                v-for="(item, index) in items"
                :key="index"
                :class="item.isActive ? 'active' : 'aasa'"
                @click="onClickItem(index, name)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
<!--          <div class="detailed-list-img" @click="updowns(name, indexs)">-->
<!--            <div-->
<!--              :class="{-->
<!--                'list-img-mored': true,-->
<!--                'list-img-mored-up': shopHeight[indexs].clickHeight != 0,-->
<!--              }"-->
<!--            ></div>-->
<!--          </div>-->
        </div>

        <!--店铺列表-->
        <div class="shop-list" v-loading="pageLoading">
          <!--一条店铺信息开始-->
          <div
            class="shop-list-item"
            v-for="(item, index) in companyList"
            :key="index"
          >
            <div
              :class="
                item.isInternalCompany != 1
                  ? 'shop-list-img list-img-blue'
                  : 'shop-list-img list-img-yellow'
              "
            ></div>
            <div class="shop-list-item-border">
              <div class="shop-list-body">
                <div class="list-body-title">
                  <p>{{ item.companyName }}</p>
                  <p v-if="item.isInternalCompany == 1">直营店铺</p>
                </div>
                <div class="list-body-main">
                  <p>主营：{{ item.mainProducts || "暂无" }}</p>
                </div>
                <div class="list-body-address">
                  <p>地址：{{ item.address }}</p>
                </div>
                <div class="list-body-tell">
                  <img src="https://ddmallimg.ddmg.com/public/assets/img/index/shops/phone.svg" alt="大大买钢"/>
                  <p>{{ item.phone }}</p>
                </div>
              </div>
              <div class="shop-list-in" @click="goDetail(item)">
                <p>进店逛逛</p>
              </div>
            </div>
          </div>
          <div class="div_404 empty-tips-company" v-if="companyList.length == 0">
            <div class="div_404_left"></div>
            <div class="div_404_right">
              <h3>~未匹配到店铺</h3>
            </div>
          </div>
          <div class="stock-paging" v-if="companyList.length > 0">
            <el-pagination
              background
              layout="total,prev, pager, next,jumper"
              :total="pagination.total"
              :page-size="num"
              @current-change="gotoNext"
              :current-page="pagination.pages"
            >
            </el-pagination>
          </div>
          <!--一条店铺信息结束-->
        </div>

        <!--底部资质-->
        <div class="shop-excellent">
          <div class="shop-excellent-list">
            <!--一条资质开始-->
            <div class="shop-excellent-item">
              <div class="excellent-list-img">
                <img src="../../assets/img/1@2x.png" alt="大大买钢"/>
              </div>
              <div class="excellent-list-body">
                <div class="list-body-title">优质服务</div>
                <div class="list-body-decs">专业人员，耐心解答</div>
              </div>
            </div>
            <!--一条资质结束-->
            <!--一条资质开始-->
            <div class="shop-excellent-item">
              <div class="excellent-list-img">
                <img src="../../assets/img/2@2x.png" alt="大大买钢" />
              </div>
              <div class="excellent-list-body">
                <div class="list-body-title">店铺认证</div>
                <div class="list-body-decs">企业认证，诚信可靠</div>
              </div>
            </div>
            <!--一条资质结束-->
            <!--一条资质开始-->
            <div class="shop-excellent-item">
              <div class="excellent-list-img">
                <img src="../../assets/img/3@2x.png" alt="大大买钢"/>
              </div>
              <div class="excellent-list-body">
                <div class="list-body-title">现货无忧</div>
                <div class="list-body-decs">真实货源，买卖无忧</div>
              </div>
            </div>
            <!--一条资质结束-->
          </div>
        </div>
      </section>
    </div>
  </comprehensive>
</template>

<script>
import comprehensive from "../../components/comprehensive.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'shoplist',
  components: {
    comprehensive,
  },
  data() {
    return {
      num: 20,
      detailsTab: false, //控制商铺切换
      shopHeight: [{ clickHeight: 0 }, { clickHeight: 0 }],
      clickHeightNum: 0,
      seachListName: {
        regionListV2: "地区",
        productNameList: "主营产品",
      },
      aaArr: [],
      searchData:{
          mainBusinessCategory:'',
          area:'',
          pages:1,                //类型：Number  必有字段  备注：页数
          rows:20,                //类型：Number  必有字段  备注：每页条数
      },
    };
  },
  methods: {
    ...mapActions("ddmg/shop", ["getRegionAndProduct", "getCompany"]),
    goDetail(item){
      this.$router.push({ name: "shop", query: { 'id':item.companyId} });
    },
    //翻页
    gotoNext(val) {
      //分页
      if (val < 1 || val > this.pagination.total_pages) {
        this.$message.error("当前没有更多页面了");
        return;
      }
      this.searchData.pages = val;
      this.getCompany( this.searchData);
    },
    // updowns(name, index) {
    //   if (this.shopHeight[index].clickHeight > 0) {
    //     // this.clickHeightNum = 0;
    //     this.shopHeight[index].clickHeight = 0;
    //   } else {
    //     let childWith = this.$refs[name][0].childNodes;
    //
    //     let num = 0;
    //     for (let i of childWith) {
    //       num += i.offsetWidth + 22.5;
    //     }
    //
    //     this.clickHeightNum = Math.ceil(num / 680);
    //     if (this.clickHeightNum == 0) {
    //       this.$message.warning("当前下拉无更多选项");
    //     } else {
    //       this.shopHeight[index].clickHeight = this.clickHeightNum;
    //     }
    //   }
    // },
    onClickItem(index, idx) {
      this.regionProductList[idx].forEach((item) => {
        item.isActive = false;
      });
      this.regionProductList[idx][index].isActive = true;
      this.regionProductList.productNameList.forEach((item) => {
        if (item.isActive == true) {
            if(item.name != '全部'){
                this.searchData.mainBusinessCategory = item.name;
            }else{
                this.searchData.mainBusinessCategory ='';
            }
        }
      });
      this.regionProductList.regionListV2.forEach((item) => {
        if (item.isActive == true) {
            if(item.name != '全部'){
                this.searchData.area = item.name;
            }else{
                this.searchData.area ='';
            }
        }
      });
      this.searchData.pages = 1;
      this.getCompany( this.searchData);
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapState("ddmg/shop", {
      companyList: (state) => state.companyList,
      regionProductList: (state) => state.regionProductList,
      pagination: (state) => state.pagination,
      pageLoading:(state) => state.pageLoading2,
    }),
  },
  created() {
    this.getRegionAndProduct();
    this.getCompany({pages:1,rows:30});
  },
  mounted() {
      // this.regionProductList[key].map((items) => {
      //     items.isActive = false;
      //     return items;
      // });
    // Object.keys(this.regionProductList).forEach((key) => {
    //   this.regionProductList[key].map((items) => {
    //     items.isActive = false;
    //     return items;
    //   });
    // });
  },
};
</script>

<style lang="scss" scoped>
.stock-paging {
  display: flex;
  justify-content: center;
  margin-bottom: 43px;
  align-items: center;
  margin-top: 43px;
}
.div_404 {
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  text-align: center;
}
.div_404_left {
  width: 350px;
  height: 300px;
  background: url("../../assets/img/404.png") no-repeat center;
  background-size: 100% 100%;
  float: left;
  margin-left: 220px;
  margin-top: 100px;
}
.div_404_right h3 {
  font-size: 24px;
  color: #ff6200;
  margin-top: 68px;
  text-align: left;
  font-weight: 400;
}
.div_404_right {
  float: left;
  height: 300px;
  margin-top: 100px;
  margin-left: 20px;
  text-align: left;
}
.shop-context {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.shop-context .shop-banner {
  width: 1200px;
  margin: 0 auto;
}

.shop-context .shop-banner img {
  width: 100%;
  display: block;
}

.shop-context .shop-address {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  border: 1px solid #ddddddff;
  border-top: none;
  height: auto;
  font-size: 16px;
  font-weight: 400;
}

.shop-context .shop-address .address-title {
  margin-left: 20px;
  margin-right: 58px;
  color: #999999ff;
  line-height: 46px;
  width: 67px;
}
.shop-context .shop-address .address-list {
  justify-content: space-between;
  width: 980px;
  transition: all 1s ease;
}
.shop-context .shop-address .address-list ul {
  display: flex;
  color: #333333ff;
  flex-wrap: wrap;
}

.shop-context .shop-address .address-list ul li {
  margin-right: 18px;
  cursor: pointer;
  font-size: 14px;
}

.shop-context .shop-address .address-list ul li.active {
  color: #e73352ff;
}

.shop-context .shop-address .address-list ul li:nth-child(1) {
  margin-right: 35px;
}

.shop-context .shop-list {
  width: 1200px;
  margin: 20px auto;
  border: 1px solid #00000029;
  display: flex;
  flex-direction: column;
  transition: all 1s ease;
}

.shop-context .shop-list .shop-list-item {
  display: flex;
  align-items: center;
  height: 171px;
}

.shop-context .shop-list .shop-list-img {
  width: 68px;
  height: 68px;
  margin-left: 16px;
  margin-right: 30px;
}

.shop-context .shop-list .list-img-yellow {
  background: url(../../assets/indeximg/3423.svg) no-repeat;
  background-size: 100%;
}

.shop-context .shop-list .list-img-blue {
  background: url(../../assets/indeximg/3422.svg) no-repeat;
  background-size: 100%;
}

.shop-context .shop-list .shop-list-body {
  display: flex;
  flex-direction: column;
}

.shop-list .shop-list-body .list-body-title {
  color: #333333ff;
  font-size: 20px;
  width: 360px;
  height: 28px;
  display: flex;
  align-items: center;
  margin-top: 26px;
}

.shop-list .shop-list-body .list-body-title p:nth-child(1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 260px;
  margin-right: 12px;
}

.shop-list .shop-list-body .list-body-title p:nth-child(2) {
  font-size: 12px;
  color: #fc9248ff;
  border: 1px solid #fc9248ff;
  border-radius: 4px;
  width: 85px;
  text-align: center;
  line-height: 21px;
  height: 21px;
}

.shop-list .shop-list-body .list-body-main {
  font-size: 14px;
  font-weight: 400;
  color: #999999ff;
  width: 340px;
  height: 20px;
  margin-top: 12px;
}
.shop-list .shop-list-body .list-body-main p{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.shop-list .shop-list-item-border {
  display: flex;
  border-bottom: 1px solid #ddddddff;
  align-items: center;
}

.shop-list .shop-list-body .list-body-address {
  font-size: 14px;
  font-weight: 400;
  color: #999999ff;
  width: 781px;
  height: 20px;
  margin-top: 7px;
}

.shop-list .shop-list-body .list-body-address p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.shop-list .shop-list-body .list-body-tell {
  display: flex;
  align-items: center;
  width: 781px;
  height: 20px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.shop-list .shop-list-body .list-body-tell img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.shop-list .shop-list-body .list-body-tell p {
  font-size: 14px;
  font-weight: 400;
  color: #999999ff;
}

.shop-list .shop-list-in {
  cursor: pointer;
  margin-left: 117px;
  width: 110px;
  height: 38px;
  border: 1px solid #ddddddff;
  color: #e73352ff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 38px;
}
.shop-list .shop-list-in:hover {
  background-color: #ef2147ff;
  color: #fff;
}
.shop-context .shop-excellent-list {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  height: 108px;
  border: 1px solid #e5e5e5ff;
}

.shop-context .shop-excellent-list .shop-excellent-item {
  display: flex;
  align-items: center;
}

.shop-context .shop-excellent-list .shop-excellent-item img {
  width: 41px;
  height: 40px;
  margin-right: 14px;
}

.shop-context .shop-excellent-list .shop-excellent-item .list-body-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333ff;
}

.shop-context .shop-excellent-list .shop-excellent-item .list-body-decs {
  font-size: 12px;
  font-weight: 400;
  color: #999999ff;
}

.shop-context .shop-sidebar {
  width: 198px;
  position: fixed;
  right: 2.2%;
  top: 27%;
  display: flex;
  flex-direction: column;
}

.shop-context .shop-sidebar .shop-sidebar-banner img {
  width: 198px;
}

.shop-context .shop-sidebar .shop-sidebar-list {
  width: 198px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin: 0 auto;
}

.shop-context .shop-sidebar .shop-sidebar-list .sidebar-left,
.shop-context .shop-sidebar .shop-sidebar-list .sidebar-right {
  border: 1px solid #00000029;
  width: 95px;
  height: 95px;
  margin-top: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.shop-context .shop-sidebar .shop-sidebar-list img {
  width: 69px;
}

.shop-context .shop-sidebar .shop-sidebar-list p {
  font-size: 12px;
  font-weight: 400;
  color: #333333ff;
}
.shop-context .address-list {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  height: 46px;
  line-height: 46px;
  overflow: hidden;
}
.shop-context .detailed-list-img {
  line-height: 46px;
  margin-top: 14px;
}
.shop-context .detailed-list-img img {
  margin-left: 30px;
  width: 16px;
  height: 16px;
}

.shop-context .list-img-mored {
  margin-left: 30px;
  width: 16px;
  height: 16px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background: url("../../assets/img/downh.svg") no-repeat;
}
.shop-context .list-img-mored {
  transition: all 1s ease;
  transform: rotate(0deg);
}
.shop-context .list-img-mored:hover {
  background: url("../../assets/img/downred.svg") no-repeat !important;
}
.shop-context .list-img-mored-up {
  background: url("../../assets/img/uph.svg") no-repeat;
  transform: rotate(180deg);
  transition: all 1s ease;
}
.shop-context .list-img-mored-up:hover {
  background: url("../../assets/img/downred.svg") no-repeat !important;
}
</style>
