<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content 综合页面
-->
<template>
  <div>
    <headerMark />
    <headerNav />
    <headerNavTwo />

    <slot></slot>

    <footerNav />
    <slider />
  </div>
</template>

<script>
import headerMark from "./headerMark.vue";
import headerNav from "./headerNav.vue";
import headerNavTwo from "./headerNavTwo.vue";
import footerNav from "./footerNav.vue";
import slider from "./slider.vue";

export default {
  components: {
    headerMark,
    headerNav,
    footerNav,
    slider,
    headerNavTwo,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
