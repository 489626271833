<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2023-10-16 20:12:07
 * @Description: file content  头部的搜索和导航栏
-->

<template>
  <div :class="{ borders: borderStyle }">
    <div class="header-nav-one">
      <div
        class="header-nav-logo"
        @click="$router.push({ name: home })"
      >
        <img
          src="../assets/img/3367.svg"
          alt="大大买钢"
        />
      </div>
      <div class="header-nav-location">
        <el-popover
          title="切换城市"
          placement="top-start"
          width="600"
          :close-on-click-modal="false"
          v-model="clicklocation"
          trigger="click"
          v-if="!routeName"
        >
          <el-row
            @click="cityBox"
            class="cityBox"
            v-if="cityList.length > 0"
          >
            <el-button
              type="text"
              v-for="(item, index) in cityList"
              :key="index"
              @click="tabCity(item)"
            >{{ item }}</el-button>
          </el-row>

          <div
            class="locationBox"
            slot="reference"
          >
            <img
              src="../assets/img/3018.svg"
              alt="大大买钢"
            />
            <p>{{ cityName }}</p>
            <img
              src="../assets/img/4046.svg"
              alt="大大买钢"
              :class="{
                reverseimg: clicklocation == true,
                reverseimgs: clicklocation == false,
              }"
            />
          </div>
        </el-popover>
      </div>
      <div class="header-nav-search">
        <div class="nav-search-input">
          <el-input
            placeholder="输入品名、材质等关键词"
            v-model="searchWord"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="input-with-button"
              @click="onSearch()"
            ></el-button>
          </el-input>
        </div>
        <div class="nav-search-keyword">
          <ul>
            <li
              v-for="(item, index) in searchKeyWord"
              :key="index"
              @click="onSearch(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="header-nav-buttons">
        <div
          class="toBuy "
          @click="$router.push({ name: 'toBuy' })"
        >
          <img
            src="../assets/img/3148.svg"
            alt="大大买钢"
          />
          <p>我要求购</p>
        </div>
        <div
          class="shoppingCart"
          @click="clickGwc"
        >
          <img
            src="../assets/img/4112.svg"
            alt="大大买钢"
          />
          <p>购物车</p>
        </div>
      </div>
    </div>
    <slider ref="slider" />
  </div>
</template>

<script>
import axios from "axios";
import util from "@/libs/util.js";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import slider from "./slider.vue";
import storage from "@/utils/storage";
export default {
  name: "headerNav",
  components: {
    slider,
  },
  data () {
    return {
      nowCity: "",
      borderStyle: false,
      clicklocation: false,
      searchKeyWord: [
        "螺纹钢",
        "盘螺",
        "线材",
        "冷轧板",
        "不锈钢型材",
        "校直线材",
      ],
      searchWord: "",
      routeName: false,
      ipString: '',
    };
  },
  methods: {
    ...mapActions("ddmg/home", ["getAreaCity"]),
    ...mapMutations("ddmg/home", ['setCity']),
    ...mapMutations("ddmg/mall", ['setWord']),
    ...mapActions("ddmg/gwc", ["getGoodsCart", "getEditGWC", "deleteGwc"]),
    clickGwc () {
      let isToken = this.isToken();
      if (isToken) {
        let userInfo = storage.getStorage("userInfo")
        this.getGoodsCart(userInfo.company_id).then((res) => {
          this.$refs.slider.gwc = true;
        });
      }
    },
    handleSelect (val) {
      console.log(val);
    },
    handleScroll () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop > 34) {
        this.borderStyle = true;
      }
      if (scrollTop < 34) {
        this.borderStyle = false;
      }
    },
    tabCity (val) {
      console.log("cityName", val);
      this.setCity(val)
      if (this.clicklocation) {
        this.clicklocation = false;
      } else {
        this.clicklocation = true;
      }
    },
    cityBox (val) {
      console.log("545", val.target.innerHTML);
    },
    onSearch (val) {
      if (this.$route.path == '/mall') {
        if (val) {
          this.setWord(val)
        } else {
          this.setWord(this.searchWord)
        }
      } else {
        if (val) {
          this.$router.push({ name: "mall", query: { keyword: val } });
        } else {
          let keyword = this.searchWord ? this.searchWord : "";
          this.$router.push({ name: "mall", query: { keyword } });
        }
      }

    },
    async getUserIP () {
      axios.get('https://api.ipify.org?format=json').then(res => {
        console.log('res=====', res)
        if (res && res.data) {
          this.ipString = res.data.ip || ""
          console.log(this.ipString, '123123456879');
          this.getAreaCity(this.ipString);
        }
      }).catch(err => {
        this.getAreaCity("");
      })
      // this.ipString = returnCitySN['cip']
      // console.log(this.ipString, '123123456879');
      // this.getAreaCity(this.ipString);
    },
  },
  wacth: {},
  mounted () {
    if (this.$route.name == 'info') {
      this.routeName = true;
    }
    window.addEventListener("scroll", this.handleScroll);
    this.getUserIP();
  },
  computed: {
    ...mapState("ddmg/home", {
      cityName: (state) => state.cityName,
      cityList: (state) => state.cityList,
    }),
    ...mapState("ddmg/mall", {
      keyword: (state) => state.keyword,
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.header-nav-one {
  display: flex;
  height: 93px;
  line-height: 93px;
  font-family: MicrosoftYaHei;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
  max-width: 1200px;
  margin: 0 auto;
}
.borders {
  width: 100%;
  box-shadow: rgb(94, 94, 94) 0px 20px 20px -20px;
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  background: #ffffff;
  z-index: 5;
}
.reverseimg {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}
.reverseimgs {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.header-nav-logo {
  line-height: 93px;
  display: flex;
  align-items: center;
}
.header-nav-location {
  display: flex;
  margin-left: 20px;
  margin-right: 76px;
  align-items: center;
}

.locationBox img {
  margin: 0 4px;
}
.locationBox {
  display: flex;
  height: 30px;
  align-items: center;
}
.header-nav-search {
  display: flex;
  flex-direction: column;
}
.nav-search-input {
  width: 495px !important;
  height: 36px !important;
}
.header-nav-one .input-with-select input {
  border-radius: 2px !important;
  border: 1px solid #ef2147ff !important;
  border-right: none !important;
  height: 36px !important;
  line-height: 36px !important;
}

.header-nav-one .el-input-group__append {
  background-color: #ef2147ff !important;
  border-color: #ef2147ff !important;
}
.header-nav-one .input-with-button {
  height: 36px !important;
  color: #ffffff !important;
  background-color: #ef2147ff !important;
  border-color: #ef2147ff !important;
}
.nav-search-keyword ul {
  line-height: 85px;
  display: flex;
  list-style: none;
  font-family: MicrosoftYaHei;
  color: #999999;
  font-size: 12px;
}
.nav-search-keyword ul li {
  margin-left: 11px;
}
.header-nav-buttons {
  display: flex;
  align-items: center;
  margin-left: 58px;
}
.toBuy {
  display: flex;
  height: 36px;
  width: 110px;
  align-items: center;
  background-color: #ef2147ff;
  line-height: 36px;
  color: #ffffff;
  justify-content: center;
}
.toBuy img,
.shoppingCart img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.toBuy p,
.shoppingCart p {
  font-size: 14px;
  margin-left: 4px;
  line-height: 36px;
}
.shoppingCart {
  display: flex;
  height: 36px;
  width: 110px;
  align-items: center;
  border: 1px solid #dddddd;
  line-height: 36px;
  color: #666666ff;
  box-sizing: border-box;
  justify-content: center;
  margin-left: 16px;
}
.cityBox button:nth-child(1) {
  margin-left: 10px !important;
}
.nav-search-keyword ul li:hover {
  text-decoration: underline;
}
// .header-nav-two .el-menu.el-menu--horizontal .el-menu-item {
//   position: relative;
//   overflow: hidden;
// }
</style>
