<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  导航
-->
<template>
  <div
    class="header-nav-two"
    ref="MainHeader"
  >
    <nav
      v-for="(nav, index) in navs"
      :key="index"
      :class="{ nav: true, active: nav.isActive == true }"
      @click="setActiveNav(index)"
    >
      <div class="nav-title" :class="{ active: nav.isActive == true }">
        {{ nav.name }}
      </div>
      <i class="nav-underline" :style="navLineStye"></i>
    </nav>

    <!-- <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"

        @mouseenter="setCurrentNav(index)"
      >
        <el-menu-item index="1"
          >处理中心</el-menu-item
        >
        <el-menu-item index="1">处理中心</el-menu-item>
        <el-menu-item index="1">处理中心</el-menu-item>
        <el-menu-item index="3">消息中心</el-menu-item>
        <el-menu-item index="4"> 订单管理</el-menu-item>
      </el-menu> -->
  </div>
</template>

<script>
export default {
  name: "footerNav",
  data() {
    return {
      activeIndex: "",
      headernav: "",
      input3: "",
      currentNav: "",
      currentNavStyle: {},
      activeNav: "",
      activeNavStyle: {},
      navs: [
        { name: "首页", url: "/index", isActive: false },
        { name: "现货资源", url: "/mall", isActive: false },
        { name: "店铺", url: "/shoplist", isActive: false },
        { name: "集采专场", url: "/inquiry", isActive: false },
        { name: "物流专场", url: "https://rzn.ddmg.com/", isActive: false },
        { name: "供应链服务", url: "/supplyChain/index", isActive: false , target:true},
        { name: "关于我们", url: "/aboutUs/index", isActive: false  , target:true},
      ],
    };
  },
  methods: {
    setCurrentNav(index) {
      // 鼠标移动到导航时，记录临时状态
      this.currentNav = index;
      this.activeNavStyle = {};
      this.currentNavStyle = {};
      this.currentNavStyle = this.getNavStyle(index);
    },
    getNavStyle(index) {
      // 根据导航的 index 移动下划
      let childNodes = this.$refs.MainHeader.childNodes;
      // 根据当前导航的宽度，设置下划线的宽度
      // console.log('22', childNodes[index].clientWidth)
      let width = childNodes[index].clientWidth;
      // 设置下划线的默认位置
      let left = 0;
      if (index > 0) {
        // 计算左边导航的总宽度，设置为下划线的左移距离
        for (let i = 0; i < index; i++) {
          left += childNodes[i].clientWidth;
          left+=30
        }
      }
      return { width, left };
    },
    setActiveNav(index) {
      // 点击导航时，设置激活样式
      this.activeNav = index;
      // this.currentNavStyle = {};
      // this.activeNavStyle = {};
      // this.activeNavStyle = this.getNavStyle(index);
      if (this.navs[index].url.startsWith("http")) {
          window.open(this.navs[index].url, "_target");
      } else if(this.$router.currentRoute.path!==this.navs[index].url){
        if(this.navs[index].target){
            let routeData = this.$router.resolve({ path:  this.navs[index].url });
            window.open(routeData.href, '_blank');
        }else{
            for (let i of this.navs) {
                i.isActive = false;
            }
            this.navs[index].isActive = true;
            this.$router.push({ path: this.navs[index].url });
        }
      }
    },
    handleLeaveMainHeader() {
      // 鼠标离开导航栏时，重置下划线状态
      this.currentNavStyle = {};
    },
  },
  mounted() {
    for (let i = 0; i < this.navs.length; i++) {
      this.navs[i].isActive = false;
      if (this.navs[i].url == this.$router.currentRoute.path) {
        this.navs[i].isActive = true;
        this.setCurrentNav(i);
      }
    }
  },
  computed: {
    navLineStye() {
      let style = this.currentNavStyle || this.activeNavStyle;
      return {
        width: `${style.width}px`,
        left: `${style.left}px`,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.header-nav-two {
  font-family: PingFang SC;
  height: 53px;
  display: flex;
  position: relative;
  font-family: MicrosoftYaHei;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
  max-width: 1200px;
  margin: 0 auto;
  user-select: none;
  cursor:pointer;
}
.header-nav-two nav {
  list-style: none;
  color: #333333;
  font-size: 16px;
  line-height: 53px;
  padding: 0 10px;
  margin-right: 30px
}
.nav-underline {
  position: absolute;
  bottom: 0;
  display: block;
  height: 2px;
  background-color: #ef2147ff;
  transition: all 0.2s ease-out;
}
.header-nav-two .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.active {
  color: #ef2147ff;
}

.nav-title{

    font-size: 16px;
}
</style>
